import React, { useState } from 'react';
import { fetcher } from "../utlities/adapter";

const BoxLabelsButton = ({ shipmentId, boxIds }) => {
  const [loading, setLoading] = useState(false);

  const handleGetLabel = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/admin/inbound_plan_boxes/labels`,
        'POST',
        {
          shipment_id: shipmentId,
          box_ids: boxIds
        }
      );

      if (response.download_url) {
        window.open(response.download_url, '_blank');
      } else if (response.error) {
        window.topAlert(response.error, 'danger');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Error generating label';
      window.topAlert(errorMessage, 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <button 
      className="btn btn-sm btn-secondary" 
      onClick={handleGetLabel}
      disabled={loading}
    >
      {loading ? (
        <><i className="fas fa-spinner fa-spin"></i> Loading...</>
      ) : (
        <><i className="fas fa-print"></i> {boxIds.length > 1 ? 'Print All' : 'Get Label'}</>
      )}
    </button>
  );
};

export default BoxLabelsButton; 