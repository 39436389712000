import React, { useState } from 'react';
import { fetcher } from "../../utlities/adapter";
const NewBoxButton = ({ 
  inboundShipmentId, 
  inboundPlanShipmentId, 
  packingGroupId, 
  onBoxCreated 
}) => {
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        '/admin/boxes',
        'POST',
        { 
          box: {
            inbound_shipment_id: inboundShipmentId,
            inbound_plan_shipment_id: inboundPlanShipmentId,
            packing_group_id: packingGroupId,
          }
        }
      );
      
      if (response.success) {
        onBoxCreated(response.box);
        window.topAlert('Box created successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error creating box', 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <button 
      className="btn btn-primary btn-sm"
      onClick={handleCreate}
      disabled={loading}
    >
      {loading ? (
        <><i className="fas fa-spinner fa-spin"></i> Creating...</>
      ) : (
        <>Create Box</>
      )}
    </button>
  );
};

export default NewBoxButton; 