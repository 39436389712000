import React, { useState } from 'react';
import { fetcher } from "../../utlities/adapter";

const BoxItem = ({ boxId, items, remainingItems = {}, onBoxUpdated }) => {
  const [loading, setLoading] = useState(false);
  const [newItem, setNewItem] = useState({ sku: '', quantity: '' });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editQuantity, setEditQuantity] = useState('');

  // Get SKUs that are already in the box
  const existingSkus = new Set(items.map(item => item.sku));

  // Filter available SKUs to exclude ones already in the box
  const availableSkus = Object.entries(remainingItems || {})
    .filter(([sku, data]) => data.quantity > 0 && !existingSkus.has(sku))
    .map(([sku, _]) => sku);

  const updateBoxItems = async (newItems) => {
    try {
      const response = await fetcher(
        `/admin/boxes/${boxId}/items`,
        'PUT',
        { items: newItems }
      );
      
      if (response.success) {
        onBoxUpdated(response.box);
        setEditingIndex(null);
        setNewItem({ sku: '', quantity: '' });
        window.topAlert('Box updated successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error updating box', 'danger');
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    updateBoxItems(newItems);
  };

  const handleUpdateQuantity = (index) => {
    const newQuantity = parseInt(editQuantity);
    if (isNaN(newQuantity) || newQuantity <= 0) {
      window.topAlert('Please enter a valid quantity', 'danger');
      return;
    }

    const item = items[index];
    const currentQuantity = parseInt(item.quantity);
    const availableQuantity = (remainingItems[item.sku]?.quantity || 0) + currentQuantity;

    if (newQuantity > availableQuantity) {
      window.topAlert('Quantity exceeds available items', 'danger');
      return;
    }

    const newItems = [...items];
    newItems[index] = { ...item, quantity: newQuantity };
    updateBoxItems(newItems);
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    if (!newItem.sku || !newItem.quantity || newItem.quantity <= 0) return;
    
    if (newItem.quantity > remainingItems[newItem.sku]?.quantity) {
      window.topAlert('Quantity exceeds remaining items', 'danger');
      return;
    }

    const newItems = [...items, newItem];
    updateBoxItems(newItems);
  };

  return (
    <div className="box-items">
      <table className="table table-sm">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Quantity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.sku}</td>
              <td>
                {editingIndex === index ? (
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    value={editQuantity}
                    onChange={(e) => setEditQuantity(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleUpdateQuantity(index);
                      }
                    }}
                  />
                ) : (
                  <span onClick={() => {
                    setEditingIndex(index);
                    setEditQuantity(item.quantity.toString());
                  }}>
                    {item.quantity}
                  </span>
                )}
              </td>
              <td>
                {editingIndex === index ? (
                  <>
                    <button 
                      className="btn btn-sm btn-success me-1"
                      onClick={() => handleUpdateQuantity(index)}
                    >
                      <i className="fas fa-check"></i>
                    </button>
                    <button 
                      className="btn btn-sm btn-secondary"
                      onClick={() => setEditingIndex(null)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </>
                ) : (
                  <button 
                    className="btn btn-sm btn-danger"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <select
                className="form-select form-select-sm"
                value={newItem.sku}
                onChange={(e) => setNewItem({...newItem, sku: e.target.value})}
              >
                <option value="">Select SKU</option>
                {availableSkus.map(sku => (
                  <option key={sku} value={sku}>
                    {sku} ({remainingItems[sku]?.quantity} remaining)
                  </option>
                ))}
              </select>
            </td>
            <td>
              <input
                type="number"
                className="form-control form-control-sm"
                placeholder="Quantity"
                value={newItem.quantity}
                onChange={(e) => setNewItem({...newItem, quantity: e.target.value})}
                max={remainingItems[newItem.sku]?.quantity || 0}
              />
            </td>
            <td>
              <button 
                className="btn btn-sm btn-primary"
                onClick={handleAddItem}
                disabled={loading || !newItem.sku || !newItem.quantity}
              >
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BoxItem; 