import React, { useState } from 'react';

const ShipmentDetails = ({ inboundPlanShipmentId, initialDeclaredValue, initialReadyDate }) => {
  const [declaredValue, setDeclaredValue] = useState(initialDeclaredValue || '');
  const [readyDate, setReadyDate] = useState(initialReadyDate || '');

  const submitDetails = async (field, value) => {
    try {
      const response = await fetch(`/admin/inbound_plan_shipments/${inboundPlanShipmentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify({
          inbound_plan_shipment: {
            [field]: value
          }
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to update shipment details');
      }
    } catch (error) {
      console.error('Error updating shipment details:', error);
    }
  };

  const handleDeclaredValueChange = (e) => {
    const value = e.target.value;
    setDeclaredValue(value);
    submitDetails('declared_value', value);
  };

  const handleReadyDateChange = (e) => {
    const value = e.target.value;
    setReadyDate(value);
    submitDetails('ready_to_ship_window_start_date', value);
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Declared Value ($)</label>
          <input
            type="text"
            className="form-control"
            value={declaredValue}
            onChange={handleDeclaredValueChange}
            placeholder="Enter declared value"
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Ready to Ship Date</label>
          <input
            type="date"
            className="form-control"
            value={readyDate}
            onChange={handleReadyDateChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails; 