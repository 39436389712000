const fetcher = async (url, method, body, params) => {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    },
    body: JSON.stringify(body),
    params: params,
  });
  let json = await response.json();
  return json;
};

const fileUploader = async (url, method, body) => {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  const response = await fetch(url, {
    method: method,
    headers: {
      // "Content-Type": "multipart/form-data",
      "X-CSRF-Token": csrf,
    },
    body: body,
  });
  let json = await response.json();
  return json;
};

export { fetcher, fileUploader };
