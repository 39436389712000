import React, { useState } from 'react';
import { fetcher } from "../utlities/adapter";

const PrepOwnerSelect = ({ initialValue, planItemId }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleChange = async (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    setLoading(true);

    try {
      const response = await fetcher(
        `/admin/inbound_plan_items/${planItemId}`,
        'PATCH',
        {
          inbound_plan_item: {
            prep_owner: newValue
          }
        }
      );

      if (response.success) {
        window.topAlert('Prep owner updated successfully', 'success');
      } else {
        window.topAlert('Error updating prep owner', 'danger');
        setValue(initialValue); // Reset to original value on error
      }
    } catch (error) {
      window.topAlert('Error updating prep owner', 'danger');
      setValue(initialValue);
    } finally {
      setLoading(false);
    }
  };

  return (
    <select 
      className="form-select form-select-sm"
      value={value}
      onChange={handleChange}
      disabled={loading}
    >
      <option value="SELLER">SELLER</option>
      <option value="AMAZON">AMAZON</option>
      <option value="NONE">NONE</option>
    </select>
  );
};

export default PrepOwnerSelect; 