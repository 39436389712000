import React, { useState } from 'react';

const PalletManager = ({ inboundPlanShipmentId, initialPallets }) => {
  const [pallets, setPallets] = useState(initialPallets || []);

  const addPallet = () => {
    const newPallets = [...pallets, { weight: '', width: '40', length: '48', height: '' }];
    setPallets(newPallets);
    submitPallets(newPallets);
  };

  const removePallet = (index) => {
    const newPallets = pallets.filter((_, i) => i !== index);
    setPallets(newPallets);
    submitPallets(newPallets);
  };

  const updatePallet = (index, field, value) => {
    const newPallets = pallets.map((pallet, i) => {
      if (i === index) {
        return { ...pallet, [field]: value };
      }
      return pallet;
    });
    setPallets(newPallets);
    submitPallets(newPallets);
  };

  const submitPallets = async (palletsData) => {
    console.log(palletsData);
    try {
      const response = await fetch(`/admin/inbound_plan_shipments/${inboundPlanShipmentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify({
          inbound_plan_shipment: {
            pallets: palletsData
          }
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to update pallets');
      }
    } catch (error) {
      console.error('Error updating pallets:', error);
    }
  };

  return (
    <div className="pallet-manager">
      <div className="mb-3">
        <button className="btn btn-outline-primary" onClick={addPallet}>
          Add Pallet
        </button>
      </div>
      
      <table className="table">
        <thead>
          <tr>
            <th>Length</th>
            <th>Width</th>
            <th>Height</th>
            <th>Weight</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pallets.map((pallet, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={pallet.length}
                  onChange={(e) => updatePallet(index, 'length', e.target.value)}
                  placeholder="48"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={pallet.width}
                  onChange={(e) => updatePallet(index, 'width', e.target.value)}
                  placeholder="40"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={pallet.height}
                  onChange={(e) => updatePallet(index, 'height', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={pallet.weight}
                  onChange={(e) => updatePallet(index, 'weight', e.target.value)}
                />
              </td>
              <td>
                <button 
                  className="btn btn-danger"
                  onClick={() => removePallet(index)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PalletManager;