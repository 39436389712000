import React, { useState } from 'react'

const JsonFetcher = ({ url, buttonText, buttonClassName }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
      })
      const data = await response.json()
      console.log('Response:', data)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button 
      onClick={handleClick} 
      className={buttonClassName || "btn btn-secondary btn-sm"} 
      disabled={loading}
    >
      {loading ? (
        <span>
          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
          Loading...
        </span>
      ) : (
        buttonText
      )}
    </button>
  )
}

export default JsonFetcher 