import React, { useState, useEffect } from "react";
import { fetcher } from "../utlities/adapter";

const OperationStatus = ({ operationId, accountId }) => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  console.log("accountId", accountId);

  const checkStatus = async () => {
    try {
      const response = await fetcher(
        `/${accountId}/inbound_operation_status/${operationId}`,
        "GET"
      );
      
      setStatus(response);
      
      if (response.operationStatus === "IN_PROGRESS") {
        setTimeout(() => checkStatus(), 5000); // Check again in 5 seconds
      } else {
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountId && operationId) {
      checkStatus();
    }
  }, [accountId, operationId]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!status) {
    return <div>Loading opetation status for {operationId} </div>;
  }

  return (
    <div className="card my-4">
      <div className="card-body">
        <h5 className="card-title">Operation Status</h5>
        <p><strong>Operation ID:</strong> {status.operationId}</p>
        <p><strong>Status:</strong> {status.operationStatus}</p>
        <p><strong>Operation Type:</strong> {status.operation}</p>
        
        {status.operationProblems && status.operationProblems.length > 0 && (
          <div>
            <h6>Problems:</h6>
            {status.operationProblems.map((problem, index) => (
              <div key={index} className={`alert alert-${problem.severity === 'ERROR' ? 'danger' : 'warning'}`}>
                <strong>{problem.code}</strong>: {problem.message}
                {problem.details && <div><small>{problem.details}</small></div>}
              </div>
            ))}
          </div>
        )}
        
        {loading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OperationStatus;