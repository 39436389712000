import React from 'react';

const TransportationOptions = ({ transportationOptions, shipmentId }) => {
    console.log("transportationOptions", transportationOptions)
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    return new Date(dateTimeString).toLocaleString();
  };

  const formatCurrency = (amount, currencyCode) => {
    if (!amount) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD'
    }).format(amount);
  };

  const TransportationOptionModal = ({ option }) => (
    <div 
      className="modal fade" 
      id={`transportDetails${option.id}`} 
      tabIndex="-1" 
      role="dialog"
      aria-labelledby={`transportDetailsLabel${option.id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`transportDetailsLabel${option.id}`}>
              Transportation Option Details
            </h5>
            <button 
              type="button" 
              className="close" 
              data-dismiss="modal" 
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {option.carrier_appointment && (
              <>
                <h6>Carrier Appointment</h6>
                <pre className="json">
                  {JSON.stringify(option.carrier_appointment, null, 2)}
                </pre>
              </>
            )}
            {option.preconditions && (
              <>
                <h6>Preconditions</h6>
                <pre className="json">
                  {JSON.stringify(option.preconditions, null, 2)}
                </pre>
              </>
            )}
            
            <pre className="json">
              {JSON.stringify(option, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );

  const [nameFilter, setNameFilter] = React.useState('');
  const [solutionFilter, setSolutionFilter] = React.useState('');

  const filteredOptions = transportationOptions.filter(option =>
    option.carrier.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    option.shipping_solution.toLowerCase().includes(solutionFilter.toLowerCase())
  );

  const handleChooseOption = async (optionId) => {
    try {
      const response = await fetch(`/admin/inbound_plan_shipments/${shipmentId}/choose_transportation_option`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ transportation_option_id: optionId })
      });
      
      if (response.ok) {
        window.location.reload();
      } else {
        alert('Failed to choose transportation option');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to choose transportation option');
    }
  };

  return (
    <div className="card mt-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <div className="card-header bg-white">
        <h3 className="card-title fs-6 mb-0">Transportation Options</h3>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by carrier name..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by shipping solution..."
              value={solutionFilter}
              onChange={(e) => setSolutionFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-hover table-borderless text-nowrap">
            <thead>
              <tr>
                <th>Carrier</th>
                <th>Carrier Code</th>

                <th>Shipping Mode</th>
                <th>Shipping Solution</th>
                <th>Quote Amount</th>
                <th>Quote Expiration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOptions.map((option) => (
                <React.Fragment key={option.id}>
                  <tr>
                    <td>{option.carrier.name}</td>
                    <td>{option.carrier.alphaCode}</td>
                    <td>{option.shipping_mode}</td>
                    <td>{option.shipping_solution}</td>
                    <td>
                      {option.quote?.cost?.amount && (
                        <>
                          {formatCurrency(option.quote.cost.amount, option.quote.cost.currencyCode)}
                          {option.quote.cost.currencyCode}
                        </>
                      )}
                    </td>
                    <td>
                      {formatDateTime(option.quote?.expiration)}
                    </td>
                    <td>
                      <div className="btn-group">
                        <button 
                          type="button" 
                          className="btn btn-sm btn-outline-primary" 
                          data-toggle="modal" 
                          data-target={`#transportDetails${option.id}`}
                        >
                          View Details
                        </button>
                        {option.transportation_option_id === option.chosen_transportation_option_id ? (
                          <span className="badge bg-success ms-2">Chosen</span>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success ms-2"
                            onClick={() => handleChooseOption(option.transportation_option_id)}
                          >
                            Choose
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                  <TransportationOptionModal option={option} />
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransportationOptions; 