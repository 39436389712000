import React, { useState } from 'react'

const LoadShipmentsButton = ({ url, buttonText, accountId }) => {
  const [loading, setLoading] = useState(false)
  const [jobId, setJobId] = useState(null)
  const [status, setStatus] = useState(null)

  const handleClick = async () => {
    setLoading(true)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
      })
      const data = await response.json()
      setJobId(data.job_id)
      pollJobStatus(data.job_id)
    } catch (error) {
      console.error('Error:', error)
      setLoading(false)
    }
  }

  const pollJobStatus = async (jobId) => {
    try {
      const response = await fetch(`/api/job_status/${jobId}?account_id=${accountId}`)
      const data = await response.json()
      
      setStatus(data.status)
      
      // currently sidekiq status is not working and always returning completed
      if (data.status === 'completed') {
        setLoading(false)
        console.log('Job completed')
        // setTimeout(() => window.location.reload(), 1000)
      } else if (data.status === 'failed') {
        setLoading(false)
      } else {
        setTimeout(() => pollJobStatus(jobId), 2000)
      }
    } catch (error) {
      console.error('Error:', error)
      setLoading(false)
    }
  }

  return (
    <div className="d-inline-block">
      <button 
        onClick={handleClick} 
        className="btn btn-secondary btn-sm" 
        disabled={loading}
      >
        {loading ? (
          <span>
            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            Loading...
            {status && <small className="ms-1 text-muted">({status})</small>}
          </span>
        ) : (
          buttonText
        )}
      </button>
    </div>
  )
}

export default LoadShipmentsButton 