import React, { useState } from "react";
import { fetcher } from "../utlities/adapter";
import OperationStatus from "./OperationStatus";
import ApiErrorMessage from "./ApiErrorMessage";

const OperationButton = ({ 
  url, 
  method = "POST",
  buttonText,
  buttonClassName = "btn btn-primary",
  accountId,
  confirmMessage,
  params
}) => {
  const [operationId, setOperationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleClick = async () => {
    if (confirmMessage && !window.confirm(confirmMessage)) {
      return;
    }

    setLoading(true);
    setErrors(null);
    
    try {
      const jsonUrl = url.endsWith(".json") ? url : `${url}.json`;
      const response = await fetcher(jsonUrl, method, params);
      if (response.errors) {
        setErrors(response.errors);
      } else if (response.operationId) {
        setOperationId(response.operationId);
      }
    } catch (error) {
      const apiErrors = [{ code: "REQUEST_FAILED", message: error.message }];
      setErrors(apiErrors);
      
    } finally {
      setLoading(false);
    }
  };

  const clearErrors = () => {
    setErrors(null);
  };

  const clearOperationStatus = () => {
    setOperationId(null);
  };

  return (
    <div>
      <button 
        className={buttonClassName}
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? (
          <>
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Processing...
          </>
        ) : buttonText}
      </button>

      {errors && (
        <div className="mt-2 position-relative">
          <button 
            onClick={clearErrors}
            className="btn btn-sm position-absolute top-0 end-0"
            style={{ zIndex: 1 }}
            title="Clear error"
          >
            <i className="fas fa-trash-alt text-danger"></i>
          </button>
          <ApiErrorMessage errors={errors} />
        </div>
      )}

      {operationId && (
        <div className="mt-3 position-relative">
          <button 
            onClick={clearOperationStatus}
            className="btn btn-sm position-absolute top-0 end-0"
            style={{ zIndex: 1 }}
            title="Clear status"
          >
            <i className="fas fa-trash-alt text-danger"></i>
          </button>
          <OperationStatus 
            operationId={operationId}
            accountId={accountId}
          />
        </div>
      )}
    </div>
  );
};

export default OperationButton; 