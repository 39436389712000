import React, { useState, useEffect } from 'react';
import Box from './Box';
import NewBoxButton from './NewBoxButton';
import { fetcher } from "../../utlities/adapter";

const BoxList = ({ inboundShipmentId, inboundPlanShipmentId, packingGroupId, items }) => {
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingItems, setRemainingItems] = useState({});

  useEffect(() => {
    fetchBoxes();
  }, []);

  useEffect(() => {
    calculateRemainingItems();
  }, [boxes, items]);

  const calculateRemainingItems = () => {
    // Create a copy of initial quantities
    const remaining = items.reduce((acc, item) => {
      acc[item.sku] = { 
        quantity: item.quantity,
        original: item.quantity 
      };
      return acc;
    }, {});

    // Subtract quantities from boxes
    boxes.forEach(box => {
      (box.items || []).forEach(boxItem => {
        if (remaining[boxItem.sku]) {
          remaining[boxItem.sku].quantity -= parseInt(boxItem.quantity);
        }
      });
    });

    setRemainingItems(remaining);
  };

  const fetchBoxes = async () => {
    const params = new URLSearchParams();
    if (inboundShipmentId) params.append('inbound_shipment_id', inboundShipmentId);
    if (inboundPlanShipmentId) params.append('inbound_plan_shipment_id', inboundPlanShipmentId);
    if (packingGroupId) params.append('packing_group_id', packingGroupId);

    try {
      const response = await fetcher(
        `/admin/boxes?${params.toString()}`,
        'GET'
      );
      setBoxes(response.boxes || []);
    } catch (error) {
      console.error('Error fetching boxes:', error);
      window.topAlert('Error loading boxes', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const handleBoxCreated = (newBox) => {
    setBoxes([...boxes, newBox]);
  };

  const handleBoxDeleted = (boxId) => {
    setBoxes(boxes.filter(box => box.id !== boxId));
  };

  const handleBoxUpdated = (updatedBox) => {
    setBoxes(boxes.map(box => 
      box.id === updatedBox.id ? updatedBox : box
    ));
  };

  const handleBoxDuplicated = (newBox) => {
    setBoxes([...boxes, newBox]);
  };

  if (loading) {
    return <div className="text-center"><i className="fas fa-spinner fa-spin"></i> Loading...</div>;
  }

  return (
    <div className="boxes-container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">Boxes</h4>
        <NewBoxButton 
          inboundShipmentId={inboundShipmentId}
          inboundPlanShipmentId={inboundPlanShipmentId}
          packingGroupId={packingGroupId}
          onBoxCreated={handleBoxCreated}
        />
      </div>

      <div className="remaining-items mb-3">
        <h5>Remaining Items to Box</h5>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Remaining / Total</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(remainingItems).map(([sku, data]) => (
              <tr key={sku} className={data.quantity === 0 ? 'table-success' : ''}>
                <td>{sku}</td>
                <td>{data.quantity} / {data.original}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className="boxes-list mb-3 w-50">
        {boxes.map(box => (
          <Box
            key={box.id}
            box={box}
            onBoxDeleted={handleBoxDeleted}
            onBoxUpdated={handleBoxUpdated}
            onBoxDuplicated={handleBoxDuplicated}
            remainingItems={remainingItems}
          />
        ))}
      </div>
    </div>
  );
};

export default BoxList; 