import React, { useState } from 'react';
import BoxItem from './BoxItem';
import { fetcher } from "../../utlities/adapter";

const Box = ({ box, onBoxDeleted, onBoxUpdated, onBoxDuplicated, remainingItems }) => {
  const [loading, setLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    length: box.length || '',
    width: box.width || '',
    height: box.height || '',
    weight: box.weight || ''
  });

  const handleDimensionChange = (e) => {
    setDimensions({
      ...dimensions,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateDimensions = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/admin/boxes/${box.id}`,
        'PATCH',
        { box: dimensions }
      );
      if (response.success) {
        onBoxUpdated(response.box);
        window.topAlert('Box updated successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error updating box', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicate = async () => {
    try {
      const response = await fetcher(
        `/admin/boxes/${box.id}/duplicate`,
        'POST'
      );
      
      if (response.success) {
        onBoxDuplicated(response.box);
        window.topAlert('Box duplicated successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error duplicating box', 'danger');
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/admin/boxes/${box.id}`,
        'DELETE'
      );
      if (response.success) {
        onBoxDeleted(box.id);
        window.topAlert('Box deleted successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error deleting box', 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Box #{box.id}</h5>
        <div>
          <button 
            className="btn btn-sm btn-secondary me-2"
            onClick={handleDuplicate}
          >
            <i className="fas fa-copy"></i>
          </button>
          <button 
            className="btn btn-sm btn-danger"
            onClick={handleDelete}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </div>
      
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="length" className="form-label">Length</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Length"
              name="length"
              value={dimensions.length}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="width" className="form-label">Width</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Width"
              name="width"
              value={dimensions.width}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="height" className="form-label">Height</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Height"
              name="height"
              value={dimensions.height}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="weight" className="form-label">Weight</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Weight"
              name="weight"
              value={dimensions.weight}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-1 d-flex align-items-end">
            <button 
              className="btn btn-primary btn-sm mb-0"
              onClick={handleUpdateDimensions}
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="fas fa-check"></i>
              )}
            </button>
          </div>
        </div>
        
        <BoxItem 
          boxId={box.id} 
          items={box.items || []} 
          remainingItems={remainingItems}
          onBoxUpdated={onBoxUpdated}
        />
      </div>
    </div>
  );
};

export default Box; 