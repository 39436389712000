import React from 'react';

const ApiErrorMessage = ({ errors, className = "alert alert-danger" }) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className={className}>
      {errors.map((error, index) => (
        <div key={index}>
          <strong>{error.code}:</strong> {error.message}
          {error.details && <div><small>{error.details}</small></div>}
        </div>
      ))}
    </div>
  );
};

export default ApiErrorMessage; 